<template>
    <div class="card">
        <div class="card-header border-0">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0">Lotes de materia prima recibidos</h3>
                </div>
                <div class="col text-right">
                    <h3>Por elaborar</h3> <p>{{ sumaTotalPorElaborar }}</p>
                    <h3>Elaborados</h3> <p>{{ sumaTotalElaborados }}</p>
                    <!-- <h3>Disponibilidad</h3> <p>{{ sumaTotalPorElaborar - sumaTotalPrestadas }}</p> -->
                    <!-- <a href="#!" class="btn btn-sm btn-primary">Ver más</a> -->
                </div>
            </div>
        </div>

        <div class="table-responsive"> 
            <base-table thead-classes="thead-light" :data="ultimosLotes">
                <template slot="columns">
                    <th># Lote</th>
                    <th>Proveedor</th>
                    <th>Fecha de recepción</th>
                    <th>Elaborados</th>
                    <th>% Elaborados</th>
                    <th>Por Elaborar</th>
                    <!-- <th>Prestadas</th> -->
                </template>

                <template slot-scope="{row}">
                    <td :class="row.PORELABORAR == 0 ? 'tachado' : '.sinStyle'">
                        {{ row.NOMBRELOTE }}
                    </td>
                    <th scope="row" :class="row.PORELABORAR == 0 ? 'tachado' : '.sinStyle'">
                        {{ row.proveedor }}
                    </th>
                    <td :class="row.PORELABORAR == 0 ? 'tachado' : '.sinStyle'">
                        {{ row.FECHAINICIO }}
                    </td>
                    <td :class="row.PORELABORAR == 0 ? 'tachado' : '.sinStyle'">
                        {{ row.ELABORADOS }}
                    </td>
                    <td :style="row.PORELABORAR == 0 ? 'color:green' : 'color:black'">
                        {{ Math.round(row.porcentaje_elaborados) }}%
                    </td>
                    <td :class="row.PORELABORAR == 0 ? 'tachado' : '.sinStyle'">
                        {{ row.PORELABORAR }}
                    </td>
                    <!-- <td :class="row.PRESTADA == 0 ? 'tachado' : '.sinStyle'">
                        {{ row.PRESTADAS }}
                    </td> -->
                </template>

            </base-table>
        </div>

    </div>
</template>
<script>

import {
    listadoUltimosLotesRecibidos
} from "../../services/inventarios";

export default {
    name: 'cajas-x-elaborar',
    data() {
        return {
            ultimosLotes: [
            
            ],
            sumaTotalPorElaborar : 0,
            sumaTotalElaborados: 0,
            sumaTotalPrestadas : 0
        }
    },
    mounted() {
        this.getUltimosLotesRecibidos();
    },
    methods: {
        getUltimosLotesRecibidos(){
            listadoUltimosLotesRecibidos().then((res) => {
                this.ultimosLotes = res;
                this.ultimosLotes.forEach(element => {
                    this.sumaTotalPorElaborar +=  element.PORELABORAR;
                    this.sumaTotalElaborados += element.ELABORADOS;
                    // this.sumaTotalPrestadas += element.PRESTADAS;
                }); 
            });
        }
    },
}
</script>
<style scoped>
.tachado {
  text-decoration: line-through;
}
.sinStyle {
  text-decoration: none;
}
</style>
