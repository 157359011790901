<template>
    <div>
        <base-header type="gradiente" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row">
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Ventas del Mes" type="gradient-info" sub-title="84" icon="ni ni-money-coins"
                        class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 3.48%</span>
                            <span class="text-nowrap">Respecto al mes pasado</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Nuevos Clientes" type="gradiente" sub-title="2,356" icon="ni ni-single-02"
                        class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 12.18%</span>
                            <span class="text-nowrap">Respecto al mes pasado</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Ingresos" type="gradient-green" sub-title="$13,924" icon="ni ni-fat-add"
                        class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-danger mr-2"><i class="fa fa-arrow-down"></i> 5.72%</span>
                            <span class="text-nowrap">Respecto al mes pasado</span>
                        </template>
                    </stats-card>
                </div>
                <div class="col-xl-3 col-lg-6">
                    <stats-card title="Egresos" type="gradient-danger" sub-title="$4,965" icon="ni ni-fat-delete"
                        class="mb-4 mb-xl-0">
                        <template slot="footer">
                            <span class="text-success mr-2"><i class="fa fa-arrow-up"></i> 4.8%</span>
                            <span class="text-nowrap">Respecto al mes pasado</span>
                        </template>
                    </stats-card>
                </div>
            </div>
        </base-header>

        <!--Charts-->
        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-xl-8 mb-5 mb-xl-0">
                    <card type="white" header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-light text-uppercase ls-1 mb-1">Vista General</h6>
                                <h5 class="h3 text-grey mb-0">Valores de Venta</h5>
                            </div>
                            <div class="col">
                                <ul class="nav nav-pills justify-content-end">
                                    <li class="nav-item mr-2 mr-md-0">
                                        <a class="nav-link py-2 px-3" href="#"
                                            :class="{ active: bigLineChart.activeIndex === 0 }"
                                            @click.prevent="initBigChart(0)">
                                            <span class="d-none d-md-block">Mes</span>
                                            <span class="d-md-none">M</span>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link py-2 px-3" href="#"
                                            :class="{ active: bigLineChart.activeIndex === 1 }"
                                            @click.prevent="initBigChart(1)">
                                            <span class="d-none d-md-block">Semana</span>
                                            <span class="d-md-none">S</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <line-chart :height="350" ref="bigChart" :chart-data="bigLineChart.chartData"
                            :extra-options="bigLineChart.extraOptions">
                        </line-chart>

                    </card>
                </div>

                <div class="col-xl-4">
                    <card header-classes="bg-transparent">
                        <div slot="header" class="row align-items-center">
                            <div class="col">
                                <h6 class="text-uppercase text-muted ls-1 mb-1">Rendimiento</h6>
                                <h5 class="h3 mb-0">Ordenes Totales</h5>
                            </div>
                        </div>

                        <bar-chart :height="350" ref="barChart" :chart-data="redBarChart.chartData">
                        </bar-chart>
                    </card>
                </div>
            </div>
            <!-- End charts-->

            <!--Tables-->
            <div class="row mt-5">
                <div class="col-xl-8 mb-5 mb-xl-0">
                    <page-visits-table></page-visits-table>
                </div>
                <div class="col-xl-4">
                    <social-traffic-table></social-traffic-table>
                </div>
            </div>
            <!--End tables-->
        </div>
    </div>
</template>
<script>
// Charts
import * as chartConfigs from "@/components/Charts/config";
import LineChart from '@/components/Charts/LineChart';
import BarChart from '@/components/Charts/BarChart';

// Tables
import SocialTrafficTable from './Dashboard/SocialTrafficTable';
import PageVisitsTable from './Dashboard/PageVisitsTable copy.vue';

export default {
    components: {
        LineChart,
        BarChart,
        PageVisitsTable,
        SocialTrafficTable,
    },
    data() {
        return {
            bigLineChart: {
                allData: [
                    [0, 20, 10, 30, 15, 40, 20, 60, 60],
                    [0, 20, 5, 25, 10, 30, 15, 40, 40]
                ],
                activeIndex: 0,
                chartData: {
                    datasets: [],
                    labels: []
                },
                extraOptions: chartConfigs.blueChartOptions
            },
            redBarChart: {
                chartData: {
                    labels: ["Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                    datasets: [
                        {
                            label: "Ventas",
                            data: [25, 20, 30, 22, 17, 29]
                        }
                    ]
                }
            }
        };
    },
    methods: {
        initBigChart(index) {
            let chartData = {
                datasets: [
                    {
                        label: "Ventas",
                        data: this.bigLineChart.allData[index]
                    }
                ],
                labels: ["May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"]
            };
            this.bigLineChart.chartData = chartData;
            this.bigLineChart.activeIndex = index;
        }
    },
    mounted() {
        this.initBigChart(0);
    }
};
</script>
<style></style>
