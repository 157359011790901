<template>
    <div>
        <div class="card">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="mb-0">Lotes de cajas de madera recibidas</h3>
                    </div>
                    <div class="col text-right">
                        <h3>Disponibilidad</h3> <p>{{ sumaTotalElaboradosMadera }}</p>
                        <!-- <a href="#!" class="btn btn-sm btn-primary">Ver más</a> -->
                    </div>
                </div>
            </div>
    
            <div class="table-responsive"> 
                <base-table thead-classes="thead-light" :data="ultimosLotesMadera">
                    <template slot="columns">
                        <th># Lote</th>
                        <th>Proveedor</th>
                        <th>Fecha de recepción</th>
                        <th>Recibidos</th>
                        <th>% Elaborados</th>
                        <th>Por Elaborar</th>
                        <th>Disponibles</th>
                    </template>
    
                    <template slot-scope="{row}">
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.NOMBRELOTE }}
                        </td>
                        <th scope="row" :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.proveedor }}
                        </th>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.FECHAINICIO }}
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.ELABORADOS }}
                        </td>
                        <td :style="row.DISPONIBLES == 0 ? 'color:green' : 'color:black'">
                            {{ Math.round(row.porcentaje_elaborados) }}%
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.PORELABORAR }}
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.DISPONIBLES }}
                        </td>
                    </template>
    
                </base-table>
            </div>
    
        </div>
        <div class="card">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="mb-0">Lotes de cajas de Plastico recibidas</h3>
                    </div>
                    <div class="col text-right">
                        <h3>Disponibilidad</h3> <p>{{ sumaTotalElaboradosPlastico }}</p>
                        <!-- <a href="#!" class="btn btn-sm btn-primary">Ver más</a> -->
                    </div>
                </div>
            </div>
    
            <div class="table-responsive"> 
                <base-table thead-classes="thead-light" :data="ultimosLotesPlastico">
                    <template slot="columns">
                        <th># Lote</th>
                        <th>Proveedor</th>
                        <th>Fecha de recepción</th>
                        <th>Recibidos</th>
                        <th>% Elaborados</th>
                        <th>Por Elaborar</th>
                        <th>Disponibles</th>
                    </template>
    
                    <template slot-scope="{row}">
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.NOMBRELOTE }}
                        </td>
                        <th scope="row" :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.proveedor }}
                        </th>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.FECHAINICIO }}
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.ELABORADOS }}
                        </td>
                        <td :style="row.DISPONIBLES == 0 ? 'color:green' : 'color:black'">
                            {{ Math.round(row.porcentaje_elaborados) }}%
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.PORELABORAR }}
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.DISPONIBLES }}
                        </td>
                    </template>
    
                </base-table>
            </div>
    
        </div>
        <div class="card">
            <div class="card-header border-0">
                <div class="row align-items-center">
                    <div class="col">
                        <h3 class="mb-0">Lotes de cajas de cartón recibidas</h3>
                    </div>
                    <div class="col text-right">
                        <h3>Disponibilidad</h3> <p>{{ sumaTotalElaboradosCarton }}</p>
                        <!-- <a href="#!" class="btn btn-sm btn-primary">Ver más</a> -->
                    </div>
                </div>
            </div>
    
            <div class="table-responsive"> 
                <base-table thead-classes="thead-light" :data="ultimosLotesCarton">
                    <template slot="columns">
                        <th># Lote</th>
                        <th>Proveedor</th>
                        <th>Fecha de recepción</th>
                        <th>Recibidos</th>
                        <th>% Elaborados</th>
                        <th>Por Elaborar</th>
                        <th>Disponibles</th>
                    </template>
    
                    <template slot-scope="{row}">
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.NOMBRELOTE }}
                        </td>
                        <th scope="row" :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.proveedor }}
                        </th>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.FECHAINICIO }}
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.ELABORADOS }}
                        </td>
                        <td :style="row.DISPONIBLES == 0 ? 'color:green' : 'color:black'">
                            {{ Math.round(row.porcentaje_elaborados) }}%
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.PORELABORAR }}
                        </td>
                        <td :class="row.DISPONIBLES == 0 ? 'tachado' : '.sinStyle'">
                            {{ row.DISPONIBLES }}
                        </td>
                    </template>
    
                </base-table>
            </div>
    
        </div>
    </div>
</template>
<script>

import {
    listadoUltimosLotesCajasRecibidos
} from "../../services/inventarios";

export default {
    name: 'cajas-elaboradas',
    data() {
        return {
            ultimosLotesMadera: [
            
            ],
            sumaTotalElaboradosMadera : 0,
            ultimosLotesPlastico: [
            
            ],
            sumaTotalElaboradosPlastico : 0,
            ultimosLotesCarton: [
            
            ],
            sumaTotalElaboradosCarton : 0
        }
    },
    mounted() {
        this.getUltimosLotesRecibidos();
    },
    methods: {
        getUltimosLotesRecibidos(){
            listadoUltimosLotesCajasRecibidos('M').then((res) => {
                this.ultimosLotesMadera = res;
                this.ultimosLotesMadera.forEach(element => {
                    this.sumaTotalElaboradosMadera += element.DISPONIBLES;
                });
            });
            listadoUltimosLotesCajasRecibidos('P').then((res) => {
                this.ultimosLotesPlastico = res;
                this.ultimosLotesPlastico.forEach(element => {
                    this.sumaTotalElaboradosPlastico += element.DISPONIBLES;
                });
            });
            listadoUltimosLotesCajasRecibidos('C').then((res) => {
                this.ultimosLotesCarton = res;
                this.ultimosLotesCarton.forEach(element => {
                    this.sumaTotalElaboradosCarton += element.DISPONIBLES;
                });
            });
        }
    },
}
</script>
<style scoped>
.tachado {
  text-decoration: line-through;
}
.sinStyle {
  text-decoration: none;
}
</style>
