<template>
    <div>
        <base-header type="gradient-info" class="pb-6 pb-8 pt-5 pt-md-8">
            <!-- Card stats -->
            <div class="row"></div>
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col-md-12">
                    <div class="card shadow" :class="''">
                        <div class="card-header border-0" :class="''">
                            <div class="row container">
                                <div class="col-12 col-md-8">
                                    <h3 class="mb-0" :class="''">
                                        <base-button iconOnly outline rounded type="success" size="sm"
                                            v-on:click="regresar" icon="ni ni-bold-left" v-b-popover.hover.top
                                            title="Regresar"></base-button>
                                        Recepcion: <span class="name mb-0 text-sm"></span>
                                    </h3>
                                </div>
                            </div>
                        </div>

                        <div class="row align-items-center">
                            <div class="col-12 col-md-1"></div>
                            <div class="col-12 col-md-10">
                                <card type="secondary">
                                    <template>
                                        <form @submit.prevent>
                                            <div style="" class="col-12 col-md-12">
                                                <h6 class="heading-small text-muted mb-2">
                                                    Datos de la recepcion
                                                    <i class="fas fa-truck-loading"></i>
                                                </h6>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input alternative="" label="Folio / Carta"
                                                            placeholder="Folio / Carta"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.folio_carta" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input alternative="" label="Fecha de solicitud"
                                                            placeholder="Fecha de solicitud"
                                                            input-classes="form-control-alternative" type="date"
                                                            v-model="recepcion.fechaSolicitud" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input alternative="" label="Fecha de recepción"
                                                            placeholder="Fecha de recepción"
                                                            input-classes="form-control-alternative" type="date"
                                                            v-model="recepcion.fechaRecepcion" />
                                                    </div>
                                                    <!-- <div class="col-sm-12 col-md-6">
														<base-input alternative="" label="Apellido Paterno" placeholder="Apellido Paterno"
															input-classes="form-control-alternative" v-model="cliente.apellidoP" v-upper-case />
													</div>
												</div>
												<div class="row">
													<div class="col-sm-12 col-md-6">
														<base-input alternative="" label="Apellido Materno" placeholder="Apellido Materno"
															input-classes="form-control-alternative" v-model="cliente.apellidoS" v-upper-case />
													</div> -->
                                                    <!-- <div class="col-sm-12 col-md-6">
                                                        <label style="margin-bottom: 0" for="datepicker"
                                                            class="form-control-label mb-2">Fecha de
                                                            Nacimiento</label>
                                                        <b-form-datepicker size="sm" id="datepicker"
                                                            placeholder="Fecha de Nacimiento"
                                                            label-help="Selecciona la fecha con el puntero."
                                                            class="mb-2" v-model="cliente.fechaNacimiento"
                                                            :date-format-options="{
                                                                year: 'numeric',
                                                                month: 'numeric',
                                                                day: 'numeric'
                                                            }">
                                                        </b-form-datepicker>
                                                    </div> -->
                                                </div>
                                                <div class="row">
                                                    <!-- <div class="col-sm-12 col-md-4">
                                                        <base-input alternative=" " label="Codigo Postal"
                                                            placeholder="Codigo Postal"
                                                            input-classes="form-control-alternative"
                                                            v-model="cliente.codigoPostal" v-upper-case />
                                                    </div> -->
                                                    <div class="col-sm-12 col-md-6">
                                                        <label class="form-control-label"
                                                            for="proveedor">Proveedor</label>
                                                        <select name="proveedor" id="proveedor"
                                                            class="form-control form-control-alternative"
                                                            aria-describedby="addon-right addon-left"
                                                            v-model="recepcion.idProveedor">
                                                            <option value="SELECCIONA UNA OPCION" disabled selected>
                                                                SELECCIONA UNA OPCIÓN
                                                            </option>
                                                            <option v-for="proveedor of proveedores" :key="proveedor.id"
                                                                :value="proveedor.id">
                                                                {{ proveedor.nombre }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-12 col-md-6">
                                                        <base-input alternative="" label="Subtotal"
                                                            placeholder="Subtotal"
                                                            input-classes="form-control-alternative" v-model="subtotal"
                                                            disabled />
                                                    </div>
                                                </div>
                                                <!-- <div class="row">
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input alternative="" label="RFC" placeholder="RFC"
                                                            input-classes="form-control-alternative"
                                                            v-model="cliente.rfc" v-upper-case />
                                                    </div>
                                                </div> -->
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="number" alternative="" label="Costo de madera"
                                                            placeholder="Costo de madera"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.costoMadera" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="number" alternative="" label="Costo de flete"
                                                            placeholder="Costo de flete"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.costoFlete" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="number" alternative="" label="Costo descarga"
                                                            placeholder="Costo descarga"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.costoDescarga" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="number" alternative=""
                                                            label="Cantidad cabezales" placeholder="Cantidad cabezales"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.cantCabezales" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="number" alternative=""
                                                            label="Cantidad tabletas" placeholder="Cantidad tabletas"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.cantidadTabletas" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="number" alternative="" label="No. Cajas"
                                                            placeholder="No. Cajas"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.numCajas" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="text" alternative="" label="Modelo"
                                                            placeholder="Modelo"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.camion.modelo" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="text" alternative="" label="Placa"
                                                            placeholder="Placa"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.camion.placa" />
                                                    </div>
                                                    <div class="col-sm-12 col-md-4">
                                                        <base-input type="text" alternative="" label="Nombre Chofer"
                                                            placeholder="Nombre Chofer"
                                                            input-classes="form-control-alternative"
                                                            v-model="recepcion.camion.nombreChofer" />
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 text-right mt-3 mb-3" v-if="id == 0">
                                                        <base-button type="warning" icon="ni ni-fat-delete">
                                                            Limpiar
                                                        </base-button>
                                                        <base-button type="success" icon="ni ni-fat-add"
                                                            v-on:click="save">Guardar</base-button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </template>
                                </card>
                            </div>
                        </div>
                        <div class="card-footer d-flex justify-content-end marginBotton" :class="''"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import storageSession from "../../services/storage.js";
import Proveedores from "../../components/Utils/proveedores.js";
// import { listarSucursales } from "../../services/sucursales";
import { getById, updateCliente } from "../../services/clientes.js";
import { create } from "../../services/recepciones.js";
import alerta from "../../services/Alertas.js";
export default {
    name: "Recepcion-madera",
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    data() {
        let sesion = storageSession.getObject("sesion");
        return {
            cliente: {
                uidSucursal: {
                    _id: "SELECCIONA"
                }
            },
            sesion,
            proveedores: Proveedores,
            sucursales: "",
            pagina: 1,
            totalPaginas: 1,
            recepcion: {
                idRecepcion: null,
                fechaRecepcion: null,
                fechaSolicitud:null,
                folio_carta: "",
                idProveedor: "SELECCIONA UNA OPCION",
                subtotal: 0.00,
                costoMadera: 0.00,
                costoFlete: 0.00,
                costoDescarga: 0,
                cantCabezales: 0,
                cantidadTabletas: 0,
                numCajas: 0,
                codUsuario: 0,
                costoXCaja: 0,
                camion:{
                    modelo:"",
                    placa:"",
                    nombreChofer:""
                }
            },
        };
    },
    computed: {
        subtotal() {
            const costoMadera = isNaN(parseFloat(this.recepcion.costoMadera)) ? 0 : parseFloat(this.recepcion.costoMadera);
            const costoFlete = isNaN(parseFloat(this.recepcion.costoFlete)) ? 0 : parseFloat(this.recepcion.costoFlete);
            const costoDescarga = isNaN(parseFloat(this.recepcion.costoDescarga)) ? 0 : parseFloat(this.recepcion.costoDescarga);

            const totalCost = costoMadera + costoFlete + costoDescarga;
            return totalCost.toFixed(2);
        }
    },
    created() {
        this.cliente.estado = "SELECCIONA UNA OPCION";
        if (this.id != "") {
            getById(this.id).then(res => {
                this.cliente = res.clientes;
            });
        }
    },
    methods: {
        save() {
            let recepcionACrear = {
                idRecepcion: this.recepcion.idRecepcion,
                fechaRecepcion: this.recepcion.fechaRecepcion,
                fechaSolicitud: this.recepcion.fechaSolicitud,
                folio_carta: this.recepcion.folio_carta,
                idProveedor: this.recepcion.idProveedor,
                subtotal: this.recepcion.subtotal,
                costoMadera: this.recepcion.costoMadera,
                costoFlete: this.recepcion.costoFlete,
                costoDescarga: this.recepcion.costoDescarga,
                cantCabezales: this.recepcion.cantCabezales,
                cantidadTabletas: this.recepcion.cantidadTabletas,
                numCajas: this.recepcion.numCajas,
                codUsuario: this.recepcion.codUsuario,
                costoXCaja: this.recepcion.costoXCaja,
                tipoLote: 4,
                camionPlaca: this.recepcion.camion.placa,
                nombreChofer: this.recepcion.camion.nombreChofer,
                modelo: this.recepcion.camion.modelo
            };
            create(recepcionACrear).then(() => {
                alerta.toast("Guardado", "success");
                this.$router.push({
                    name: "recepcion"
                });
            }).catch((error) => {
                alerta.toast("Ha ocurrido un error en el guardado", "error")
                console.error(error);
            });
        },
        // validarPuntoDecimal(valor) {
        //     const regex = /^[0-9]*\.?[0-9]*$/;
        //     return regex.test(valor);
        // },
        // validarNumero(event) {
        //     const valor = event.target.value;
        //     if (!this.validarPuntoDecimal(valor)) {
        //         // Si el valor no es válido, puedes mostrar un mensaje de error o restringir la entrada
        //         this.recepcion.costoMadera = valor.slice(0, -1); // Elimina el último carácter
        //     }
        // },
        update() {
            updateCliente(this.cliente, this.cliente.uid).then(() => {
                alerta.toast("Guardado", "success");
                this.$router.push({
                    name: "clientes"
                });
            });
        },
        getCliente(id) {
            getById(id).then(res => (this.cliente = res));
        },
        regresar() {
            this.$router.go(-1);
        },
        cancelar() {
            this.$router.go(-1);
        }
    }
};
</script>

<style>
.font-weight-bold {
    color: black;
}

#datepicker__value_ {
    margin-top: 2px;
    margin-bottom: 2px;
}
</style>
